<template>
  <div>
    <div class="clearfix"><b-form-group
      id="fieldset-status"
      class="float-right"
      label-cols="3"
      label="Status:"
      label-for="input-status"
    ><b-form-select id="input-status" class="ml-2" style="width: auto" v-model="showArchived" :options="[{value: 0, text: 'Active'},{value: 1, text: 'Archived'}]"></b-form-select></b-form-group></div>
    <b-card-group columns>
      <template v-for="(post, index) in content">
      <b-card
        v-if="post.archived===compareArchived"
        no-body
        class="cursor-pointer mt-3"
        :key="index"
        img-alt="Image"
        img-top
      >
          <b-card-img v-if="post && post.options && post.options.featuredImage" :src="post.options.featuredImage.src"></b-card-img>
          <b-card-body>
            <b-card-title>{{ post.title }}</b-card-title>
            <b-card-text>
              {{ post.summary }}
            </b-card-text>
          </b-card-body>
          <div slot="footer" class="clearfix">
            <small class="text-muted float-left">Posted: {{ $moment(post.dateStart).format('MM/DD/YY') }}</small>
            <span class="float-right">
              <small><router-link class="text-warning" :to="'/website-local/happening-now/form/' + post.id">edit</router-link>
              | <a class="text-success" :href="'//' + sitePublicDomain + '/' + currentBusiness.uriName+'/happening-now/' + post.slug" target="_blank">Read More</a>
              <span v-if="!showArchived"> | <a class="text-warning" href="#" @click.prevent="setArchivedStatus(post.id, true)">Archive</a></span>
              <span v-else> | <a class="text-warning" href="#" @click.prevent="setArchivedStatus(post.id, false)">Unarchive</a></span>
              <span v-if="showArchived"> | <a class="text-danger" href="#" @click.prevent="postDelete(post.id)">Delete</a></span>
              </small></span>
          </div>
      </b-card>
      </template>
    </b-card-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data () {
    return {
      showArchived: 0
    }
  },
  computed: {
    ...mapGetters([
      'content'
    ]),
    compareArchived () {
      return this.showArchived ? true : false
    }
  },
  methods: {
    ...mapActions([
      'getContent',
      'putContent',
      'deleteContent',
      'resetContent'
    ]),
    doGetContent () {
      this.getContent({
        business: this.currentBusiness.id,
        type: 'happening_post',
        // 'date_start[before]': this.$moment().format('YYYY-MM-DD'),
        archived: this.showArchived,
        'order[date_start]': 'desc'
      })
    },
    async setArchivedStatus (id, archive = true) {
      if (confirm(archive ? 'ARCHIVE THE POST?' : 'UNARCHIVE THE POST AND MAKE IT ACTIVE?')) {
        await this.putContent({
          id: id,
          archived: archive
        })
        this.doGetContent()
      }
    },
    async postDelete (id) {
      if (confirm(`DELETE THE POST?
      
      :: THIS CANNOT BE UNDONE! ::`)) {
        await this.deleteContent(id)
        this.doGetContent()
      }
    }
  },
  watch: {
    showArchived () {
      this.doGetContent()
    }
  },
  mounted () {
    this.doGetContent()
  },
  beforeDestroy () {
    this.resetContent()
  }
}
</script>
